<template>
  <div>
    <PersonnelExperience/>
  </div>
</template>

<script>
import PersonnelExperience from '../../components/personnelExperience.vue';

export default {
    name: 'HRvecPersonnelExperience',
    data() {
        return {};
    },
    mounted() {
    },
    methods: {},
    components: { PersonnelExperience }
};
</script>

<style lang="scss" scoped>

</style>